import React from 'react';
import {Link} from 'gatsby';
import classNames from 'classnames';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';

export default ({
	products = [],
	title,
	description,
	discountBadge,
	cents,
	collectionTileTitle,
	mainImage,
	mainImageLandscape,
}) => (
		<div className="db" style={{display:'none'}}>
			<div className="bg--gray-bg mb2">
				<div className="row gutter--none bundle-tile__row fdr">
					<div className="col c4 show--lg bundle-tile__col fdc">
						<div className="pr x y">
							<Link
								to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}
								className="link--trigger di" aria-label={collectionTileTitle}>
							<div className="image bundle-tile__image x y">
								<picture>
									<source
										srcSet={`${mainImage.url}?w=900&auto=format&q=75`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${mainImage.url}?w=600&auto=format&q=75`}
										media="(min-width: 600px)"
									/>
									<img
										alt={mainImage.alt}
										src={`${mainImage.url}?w=400&auto=format`}
									/>
								</picture>
							</div>
							<p className="color--white pa top left right p1 serif--lg hide--lg">
								{collectionTileTitle}
							</p>
						</Link>
						</div>
					</div>
					<div className="col c8--lg bundle-tile__col fdc">
						<Link className="p3 pb0 pb3--lg df fdr jcb link--trigger" aria-label={collectionTileTitle+' '+discountBadge} to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}>
								<p className="serif--xxl show--lg pr2 color--black">
									{collectionTileTitle}
								</p>
								<p className="x hide--lg serif--lg tc color--black" aria-hidden="true">
									{collectionTileTitle}
								</p>
								<p className="button--primary show--lg white ws--nowrap">
									{discountBadge}
								</p>
						</Link>
						<Link className="p3 df fdr jcs cx show--lg link--trigger" aria-label={collectionTileTitle +' medicines'} to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}>
							{products.map((product, index) => (
								<picture className="db mr2 bundle-tile__product">
									<img
										className="db bundle-tile__product-img"
										alt={product.title}
										srcSet={`${product.bundleExpandedImage.url}?h=300&auto=format&q=75`}
									/>
								</picture>
							))}
						</Link>
						<Link
							to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}
							aria-label={collectionTileTitle +' medicines'}
							className={classNames('hide--lg df fw p3 pb0 link--trigger', {
								jcb: products.length > 4,
								jcc: products.length <= 4,
							})}>
							{products.map((product, index) => (
								<picture
									className={classNames(
										'db bundle-tile__product mb3',
										{
											'bundle-tile__product--two-up':
												products.length <= 4,
										},
									)}>
									<img
										className="db bundle-tile__product-img"
										alt={product.title}
										srcSet={`${product.bundleExpandedImage.url}?w=300&auto=format&q=75`}
									/>
								</picture>
							))}
						</Link>
					</div>
				</div>
			</div>
			<Link
				to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}
				className="link--trigger"
				aria-label={title}
			>
				<p className="link--opacity fw--800">{title}</p>
			</Link>
			<Link
				to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}
				className="link--trigger"
				aria-label={description}
			>
				<p className="sans--xs mb2">{description}</p>

			</Link>
			<Link
				aria-label={'Buy This Cabinet '+ centsToPriceNoTrailingZeros(cents)}
				to={`/cabinets/new?productIds=${products.map(p => p._id).join(',')}`}
				className="link--trigger"
			>
					<div
						className="db button--pill--primary invert">
						Buy This Cabinet {centsToPriceNoTrailingZeros(cents)}
					</div>
			</Link>
		</div>
);

import React, {useState} from 'react';
import classNames from 'classnames';
import {connectToStore} from './Provider';
import {Transition} from 'react-transition-group';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import pluralize from 'magic-tricks/lib/pluralize';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Flickity from './Flickity';
import Select from './Select';
import {encode} from '../utils/shopify-gid';
import Radio from './Radio';
import InventoryProductProvider from './InventoryProductProvider';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import Reveal from './Reveal';
import findAccessiblityColor from '../utils/findAccessiblityColor';

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: false,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

const ProductQuickViewModal = ({
	title,
	productId,
	variantId: defaultVariantId,
	cents: defaultCents,
	color,
	drugName,
	activeIngredients,
	description = [],
	heroImages = [],
	slug,
	subscription,
	isOpen,
	isAddingItem,
	customer,
	variants,
	hideLowInventoryProduct,
	link,
	buyonamazon_link,
	upc,
	findstore_link,
	addItemAndOpenCart = () => {},
	onClose = () => {},
}) => {
	const [quantity, setQuantity] = useState(1);
	const [intervalFrequency, setIntervalFrequency] = useState(
		subscription ? subscription.orderIntervalFrequencyOptions[0] : null,
	);
	const [isSubscription, setIsSubscription] = useState(false);
	const [activeImageIndex, setImageIndex] = useState(0);
	const activeImage = heroImages[activeImageIndex];

	const discountVariant = getWholesaleVariant(variants, customer);

	const cents = discountVariant.cents;
	const variantId = discountVariant.variantId;

	let subscriptionCents = cents;

	if (subscription) {
		subscriptionCents =
			subscription.discountType === 'percentage'
				? cents - (Number(subscription.discountAmount) / 100) * cents
				: cents - Number(subscription.discountAmount) * 100;
	}

	const subscriptionOption = !!subscription && (
		<div
			className={classNames('p1 product-hero__form mb1', {
				active: isSubscription,
			})}
			style={{'--backgroundColor': color}}>
			<div className="df fdr ais jcs">
				<Radio
					onClick={() => setIsSubscription(true)}
					className="fg0"
					active={isSubscription}
				/>
				<div
					onClick={() => setIsSubscription(true)}
					className={classNames('x fg1 rich-text pl1', {
						'sans--sm-md fw--800': isSubscription,
					})}>
					<div className="df fdr jcb">
						<div>
							Subscribe and Save{' '}
							{subscription.discountType === 'percentage'
								? `${subscription.discountAmount}%`
								: centsToPriceNoTrailingZeros(
										subscription.discountAmount * 100,
								  )}
						</div>
						<div className="tr">
							<span
								className={classNames('fw--400 strike', {
									o5: !isSubscription,
								})}>
								{centsToPriceNoTrailingZeros(cents)}
							</span>{' '}
							{centsToPriceNoTrailingZeros(subscriptionCents)}
						</div>
					</div>

					{isSubscription && (
						<div className="mt1 row fw--400">
							<div className="col c8--md">
								<Select
									label="Deliver every..."
									value={intervalFrequency}
									triggerClassName="cart-item__frequency-select"
									dropdownAlignment="top left right"
									optionClassName="sans--xs--md link--opacity"
									options={subscription.orderIntervalFrequencyOptions.map(
										option => ({
											title: `Delivery every ${option} ${pluralize(
												subscription.orderIntervalUnit,
												option,
											)}`,
											value: option,
										}),
									)}
									onChange={intervalFrequency =>
										setIntervalFrequency(intervalFrequency)
									}
								/>
								<p className="sans--xs mt1">
									Change or cancel anytime.
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);

	const oneTimeOption = !!subscription && (
		<div
			className={classNames('p1 product-hero__form', {
				active: !isSubscription,
			})}
			style={{'--backgroundColor': color}}>
			<div className="df fdr ais jcs">
				<Radio
					onClick={() => setIsSubscription(false)}
					className="fg0"
					active={!isSubscription}
				/>
				<div
					onClick={() => setIsSubscription(false)}
					className={classNames('x fg1 rich-text pl1', {
						'sans--sm-md fw--800': !isSubscription,
					})}>
					<div className="df fdr jcb">
						<div>One Time Purchase</div>
						<div>{centsToPriceNoTrailingZeros(cents)}</div>
					</div>
				</div>
			</div>
		</div>
	);

	const quantityForm = (
		<div className="df fdr">
			<button
				aria-label="Remove 1"
				title="minus"
				onClick={quantity > 1 ? () => setQuantity(quantity - 1) : null}
				className="db link--opacity">
				<img
					alt="Remove 1"
					src="/images/icon-minus-cart.svg"
					className="db"
				/>
			</button>
			<p className="ml1 mr1">{quantity}</p>
			<button
				aria-label="Add 1"
				title="add"
				onClick={() => setQuantity(quantity + 1)}
				className="db link--opacity">
				<img
					alt="Remove 1"
					src="/images/icon-plus-cart.svg"
					className="db"
				/>
			</button>
		</div>
	);

	const productForm = (
		<InventoryProductProvider
			productId={productId}
			renderLoading={() => (
				<button title="Loading" aria-label="Loading" disabled className="button--primary db x">
					Loading…
				</button>
			)}>
			{({product, isAddingItem, addItemAndOpenCart}) => {
				const onAddToCart = () => {
					let customAttributes = [];

					if (subscription) {
						customAttributes.push({
							key: '_subscription_options',
							value: JSON.stringify(subscription),
						});
					}

					if (subscription && isSubscription) {
						customAttributes = [
							...customAttributes,
							{
								key: 'subscription_id',
								value: subscription._id,
							},
							{
								key: 'shipping_interval_frequency',
								value: intervalFrequency.toString(),
							},
							{
								key: 'shipping_interval_unit_type',
								value: subscription.orderIntervalUnit,
							},
						];
					}

					const encodedVariantId = encode(
						'ProductVariant',
						variantId,
						{
							accessToken:
								process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
						},
					);

					addItemAndOpenCart([
						{
							quantity,
							variantId: encodedVariantId,
							customAttributes,
						},
					]).then(() => onClose());
				};

				return (
					<button
						title="Add to Bag" 
						aria-label="Add to Bag"
						onClick={onAddToCart}
						disabled={isAddingItem}
						className={classNames('button--primary db x', {
							loading: isAddingItem,
						})}>
						{'Add to Bag - '}
						{isSubscription
							? centsToPriceNoTrailingZeros(
									subscriptionCents * quantity,
							  )
							: centsToPriceNoTrailingZeros(cents * quantity)}
					</button>
				);
			}}
		</InventoryProductProvider>
	);

	const titleSection = (
		<div>
			<h2 className="serif--lg serif--xxl--lg mb1">{title}</h2>
			<p className="fw--800 sans--sm-md--lg">{drugName}</p>
			<p className="sans--sm-md--lg">{activeIngredients}</p>
		</div>
	);

	const productLink = (
		<p>
			<Link
				aria-label="View Full Details"
				className="link--underline-invert-small"
				to={`/products/${slug}`}>
				View Full Details
			</Link>
		</p>
	);

	const imageTiles = (
		<div className="row">
			<div className="col c2 reveal__slide reveal__delay--0">
				<div className="pl2--xl">
					{heroImages.map((image, index) => (
						<button
							title={image.alt}
							aria-label={image.alt}
							key={index}
							className="db x link--opacity mb1"
							onClick={() => setImageIndex(index)}>
							<div className="x image size--3x4">
								<picture>
									<img
										alt={image.alt}
										src={`${image.url}?w=100&auto=format`}
									/>
								</picture>
							</div>
						</button>
					))}
				</div>
			</div>
			<div className="col c10 reveal__slide reveal__delay--1">
				<div className="image size--3x4">
					<picture>
						<source
							srcSet={`${activeImage.url}?w=900&auto=format&q=75`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${activeImage.url}?w=600&auto=format&q=75`}
							media="(min-width: 600px)"
						/>
						<img
							alt={activeImage.alt}
							src={`${activeImage.url}?w=400&auto=format`}
						/>
					</picture>
				</div>
			</div>
		</div>
	);

	const imageCarousel = (
		<Flickity options={SLIDE_OPTIONS}>
			{heroImages.map((image, index) => (
				<div
					key={image._key}
					className="bundle-builder__modal-slide mr2">
					<div className="image size--3x4">
						<picture>
							<source
								srcSet={`${image.url}?w=900&auto=format&q=75`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${image.url}?w=600&auto=format&q=75`}
								media="(min-width: 600px)"
							/>
							<img
								alt={image.alt}
								src={`${image.url}?w=400&auto=format`}
							/>
						</picture>
					</div>
				</div>
			))}
		</Flickity>
	);

	const inStock = !hideLowInventoryProduct && (
		<div>
			<div className="mb2">
				{/*oneTimeOption*/}
				{/*subscriptionOption*/}
			</div>
			<div className="df fdr aic">
				<div className="fg1">{/*productForm*/}</div>
				<div className="fg0 pl2">{/*quantityForm*/}</div>
			</div>
		</div>


	);

	const lowInventoryMessage = hideLowInventoryProduct && (
		<div class="rich-text lowinventory">
			<h4>We're sorry, this item is out of stock.</h4>
			<p class="mt1">Please select one of the options below to find this product online or in a store near you.</p>
			<ul class="mt mb">
				<li><a href={link} target="_blank" class="link--underline-invert-small"  aria-label="Amazon Shop" >Amazon Shop</a></li>
				<li><a href="/where-to-buy" target="_blank" class="link--underline-invert-small" aria-label="Store Locator" >Store Locator</a></li>
			</ul>
		</div>
	);


	const modalContents = (
		<div className="row">
			<div className="col c6--md">
				<div className="hide--md mb2 reveal__slide reveal__delay--0">
					<div className="mb1">{titleSection}</div>
					<div className="product-hero__reviews">
						<div
							data-bv-show="inline_rating"
							data-bv-product-id={productId}
						/>
					</div>
				</div>
				<div className="show--md reveal__slide reveal__delay--1">
					{imageTiles}
				</div>
				<div className="hide--md bundle-builder__modal-container">
					{imageCarousel}
				</div>
			</div>
			<div className="col c6--md reveal__slide reveal__delay--3">
				<div className="show--md mb1">{titleSection}</div>
				<div className="product-hero__reviews show--md">
					<div
						data-bv-show="inline_rating"
						data-bv-product-id={productId}
					/>
				</div>
				<div className="show--md rich-text line-break">
					<BlockContent blocks={description} serializers={richText} />
				</div>
				<div className="show--md mt2">{productLink}</div>
				<div className="mt3 mb3">
				{/*<div className="bt--black  bb--black pt1 mb2">
<div class="">
 		{(buyonamazon_link && !hideLowInventoryProduct) &&	<a
			href={buyonamazon_link}
			className="db button--pill--primary red" aria-label="Buy now on Amazon" target="_blank">
			Buy now on Amazon
			</a>
}
</div>
findstore_link   && <> <div className="fw--800" style={{color:findAccessiblityColor('#fc6b70')}}> {!hideLowInventoryProduct && 'or shop Genexa in your favorite stores!'} {hideLowInventoryProduct && 'This product is temporarily unavailable on Amazon, but you can find it in your favorite retail store!'} </div> 
<div class="mt2 mb3">
<a
aria-label= 'Where to buy' 
href={findstore_link}
className='button--pill--primary invert' style={{ fontSize: 20 }} target='_blank'>
	<span>
		<span className="show--md">Where to buy</span>
	</span>
</a></div></>


				</div>*/}
					{/*lowInventoryMessage*/}
				</div>
			</div>
		</div>
	);

	return (
		<Transition
			in={isOpen}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						onClick={onClose}
						className="product-quick-view__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
					/>
					<div
						className="product-quick-view__modal pf p2 pt4 pb4 p4--md p6--lg bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						<Reveal>{modalContents}</Reveal>
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={onClose}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(ProductQuickViewModal);

import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import isTouchEnabled from '../utils/isTouchEnabled';

export default ({
	productId,
	image = {url: ''},
	title,
	drugName,
	activeIngredients,
	cents,
	slug,
	isAdding,
	onAdd,
	onQuickView,
	hideLowInventoryProduct,
	link,
	collectionSlug,
	buyonamazon_link,
	upc,
}) => {
	const isTouchDevice = isTouchEnabled();
	const [isAdded, setIsAdded] = useState(false);

	const onClickAdd = () => {
		onAdd().then(() => {
			setIsAdded(true);
		});
	};

	return (
		<div>
			<div className="pr link--trigger">
				<Link to={`/products/${slug}`} className="db mb2" aria-label={title}>
					<div className="image size--3x4 link--opacity">
						<picture>
							<img
								alt={title}
								src={`${image.url}?w=500&h=666&auto=format`}
							/>
						</picture>
					</div>
				</Link>
				{!isTouchDevice && (
					<div className="pa link--hidden fill df jcc aic pen">
						<button
							title="Quick View"
							aria-label="Quick View"
							onClick={onQuickView}
							className="ws--nowrap button--primary pea">
							Quick View
						</button>
					</div>
				)}
			</div>
			<Link className="link--trigger db mb1" to={`/products/${slug}`}>
				<p className="link--opacity fw--800">{title}</p>
				<p className="fw--800 sans--xs">{drugName}</p>
				<p className="sans--xs mb1">{activeIngredients}</p>
				<div className="product-tile__reviews">
					<div
						data-bv-show="inline_rating"
						data-bv-product-id={productId}
					/>
				</div>
			</Link>

			{/* (!hideLowInventoryProduct && collectionSlug!='buy-with-prime') &&
				<button
					title={isAdded ? 'Added to Bag' : 'Add to Bag - ' + centsToPriceNoTrailingZeros(cents)}
					aria-label={isAdded ? 'Added to Bag' : 'Add to Bag - ' + centsToPriceNoTrailingZeros(cents) }
					onClick={onClickAdd}
					disabled={isAdding}
					className={classNames('button--pill--primary invert', {
						loading: isAdding,
					})}>
					{isAdded ? (
						'Added'
					) : (
						<span>
							<span>{'Add'}</span>
							<span className="show--md"> to Bag</span>
							<span>
								{' - '}
								{centsToPriceNoTrailingZeros(cents)}
							</span>
						</span>
					)}
				</button>
					*/}
				{ (!hideLowInventoryProduct && collectionSlug!='buy-with-prime') &&
				<a
				aria-label= {'Learn more ('+ slug.replace(/-/g, ' ')+')'} 
				href={`/products/${slug}`}
				className='button--pill--primary invert'>
					<span>
						<span>Learn more</span>
						<span className="show--md"></span>
					</span>
				</a>
			}
			{ (!hideLowInventoryProduct && collectionSlug=='buy-with-prime') &&
				<a
				aria-label= {'Shop Now ('+ slug.replace(/-/g, ' ')+')'} 
				href={`/products/${slug}`}
				className={classNames('button--pill--primary invert', {
					loading: isAdding,
				})}>
					<span>
						<span>Shop Now</span>
						<span className="show--md">Shop Now</span>
					</span>
				</a>
			}
		
		</div>
	);
};

import React from 'react';
import {connectToStore} from './Provider';
import ProductTileCollection from './ProductTileCollection';
import BundleTileCollection from './BundleTileCollection';
import {encode} from '../utils/shopify-gid';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import reduce from 'lodash/reduce';
import getApp from 'magic-tricks/lib/getApp';

const CollectionProductGrid = ({
	tiles = [],
	isAddingItem,
	addItemAndOpenCart,
	onQuickView,
	customer,
	bulkDiscountMinimumItems,
	bulkDiscountPercentage,
	hideLowInventoryProduct,
	link,
	collectionSlug,
}) => {
	const columns = tiles.map((tile, index) => {
		
		//
		// === Product ===
		//
		if (tile._type === 'product') {
			
			const discountVariant = getWholesaleVariant(
				tile.variants,
				customer,
			);
			const cents = discountVariant.cents;
			const variantId = discountVariant.variantId;

			const onAdd = () => {
				const app = getApp();
				const {subscription} = tile;
				const customAttributes = [];

				if (subscription) {
					customAttributes.push({
						key: '_subscription_options',
						value: JSON.stringify(subscription),
					});
				}

				const encodedVariantId = encode('ProductVariant', variantId, {
					accessToken:
						process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
				});

				// Track add to cart
				if (app.analytics) {
					app.analytics.addToCart({
						_productId: tile.productId,
						id: discountVariant.sku,
						name: tile.title,
						brand: 'Genexa',
						category: 'Medication',
						variant: discountVariant.title,
						quantity: 1,
						price: Number(discountVariant.price),
					});
				}

				return addItemAndOpenCart([
					{
						quantity: 1,
						variantId: encodedVariantId,
						customAttributes,
					},
				]);
			};
			
			return (
				<div
					key={index}
					className="col c6 c4--lg mb6 mb12--lg reveal__slide"
					style={{transitionDelay: `${index * 200 + 400}ms`}}>
					<ProductTileCollection
						productId={tile.productId}
						image={tile.mainImage}
						title={tile.title}
						cents={cents}
						slug={tile.slug}
						drugName={tile.drugName}
						activeIngredients={tile.activeIngredients}
						isAdding={isAddingItem}
						onAdd={onAdd}
						hideLowInventoryProduct={tile.hideLowInventoryProduct}
						link={tile.link}
						onQuickView={() => onQuickView(tile._id)}
						collectionSlug={collectionSlug}
						buyonamazon_link={tile.buyonamazon_link}
						upc={tile.upc}
					/>
				</div>
			);

			//
			// === Bundle ===
			//
		} else if (tile._type === 'bundle') {
			const {products = []} = tile;

			const totalCents = reduce(
				products,
				(totalCents, product) => {
					if (!product) return totalCents;

					if (products.length >= bulkDiscountMinimumItems) {
						const discountPercentage =
							(100 - bulkDiscountPercentage) / 100;
						return totalCents + product.cents * discountPercentage;
					} else {
						return totalCents + product.cents;
					}
				},
				0,
			);

			return (
				<div key={tile._key} className="col mb6 mb12--lg">
					<BundleTileCollection
						products={products}
						title={tile.title}
						description={tile.description}
						discountBadge={tile.discountBadge}
						cents={totalCents}
						collectionTileTitle={tile.collectionTileTitle}
						mainImage={tile.mainImage}
						mainImageLandscape={tile.mainImageLandscape}
					/>
			</div>
			
			);
		}

		return null;
	});
	
	return <div className="row gutter--large--lg">{columns}</div>;
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(CollectionProductGrid);

import React, {useState} from 'react';
import classNames from 'classnames';
import {Transition} from 'react-transition-group';
import find from 'lodash/find';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
	entered: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
	exited: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
};

const renderTrigger = ({onClick, isOpen, triggerClassName}) => (
	<button title="Toggle"	aria-label="Toggle" className={triggerClassName} onClick={onClick}>
		Toggle
		<img
			className={classNames('drawer__arrow-flip ml1', {
				active: isOpen,
			})}
			src="/images/icon-arrow-drawer.svg"
			alt="arrow"
		/>
	</button>
);

export default ({
	renderTrigger = renderTrigger,
	children = ({onClose, isOpen}) => null,
	dropdownAlignment = 'top left',
	triggerClassName = 'fw--800',
	dropdownClassName,
	...props
}) => {
	const [isOpen, setOpen] = useState(false);
	const onClick = () => (isOpen ? setOpen(false) : setOpen(true));
	const trigger = renderTrigger({onClick, isOpen, triggerClassName});
	const drawerContents = children({onClose: () => setOpen(false), isOpen});

	const dropdown = (
		<Transition
			in={isOpen}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: TRANSITION_DURATION,
			}}>
			{status => (
				<div
					style={{
						...TRANSITION_STYLES.default,
						...TRANSITION_STYLES[status],
					}}
					className={classNames('pa pt1 z1', dropdownAlignment)}>
					<div
						className={classNames(
							'input--select__dropdown p1',
							dropdownClassName,
						)}>
						{drawerContents}
					</div>
				</div>
			)}
		</Transition>
	);

	return (
		<div {...props}>
			{trigger}
			<div className="pr">{dropdown}</div>
		</div>
	);
};

import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import Seo from '../components/Seo';
import Drawer from '../components/Drawer';
import ProductQuickViewModal from '../components/ProductQuickViewModal';
import CollectionProductGrid from '../components/CollectionProductGrid';
import HorizontalRule from '../components/HorizontalRule';
import getApp from 'magic-tricks/lib/getApp';
import Select from '../components/Select';
import SelectDrawer from '../components/SelectDrawer';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import without from 'lodash/without';
import _sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import Reveal from '../components/Reveal';
import ComponentList from '../components/ComponentList';

const SORT_OPTIONS = [
	{
		title: 'Sorted by Featured',
		value: null,
	},
	{
		title: 'Sorted by Price: High to Low',
		value: 'highLow',
	},
	{
		title: 'Sorted by Price: Low to High',
		value: 'lowHigh',
	},
];

const getFiltersByKey = (allFilters, key) => {
	let allTileFilters = reduce(
		allFilters,
		(allFiltersOfKey, tile) => {
			if (tile._type === 'product' && tile[key]) {
				return [...allFiltersOfKey, ...tile[key]];
			} else if (tile._type === 'bundle' && tile.products) {
				const allProductFilters = reduce(
					tile.products,
					(allProductFilters, product) => {
						if (product[key]) {
							return [...allProductFilters, ...product[key]];
						} else {
							return allProductFilters;
						}
					},
					[],
				);

				return [...allFiltersOfKey, ...allProductFilters];
			}

			return allFiltersOfKey;
		},
		[],
	);

	allTileFilters = uniqBy(allTileFilters, '_id');
	allTileFilters = _sortBy(allTileFilters, 'title');

	return allTileFilters;
};

export default ({pageContext, location}) => {
	const app = getApp();
	const [activeModalProductId, setModalProductId] = useState(null);
	const [showProductModal, setShowProductModal] = useState(false);
	const [activeAgeFilters, setAgeFilters] = useState([]);
	const [activeSymptomFilters, setSymptomFilters] = useState([]);
	const [sortBy, setSortBy] = useState(null);
	const {collection = {}, template = {}, cart = {}} = pageContext;
	const {bulkDiscountMinimumItems, bulkDiscountPercentage} = cart;
	const {
		title,
		slug,
		seo = {},
		tiles = [],
		allFilters = [],
		components = [],
		hideFromSearchEngine,
		topBannerImage,
	} = collection;
	const {collectionNav = []} = template;
	const allAges = getFiltersByKey(allFilters, 'ages');
	const allSymptoms = getFiltersByKey(allFilters, 'symptoms');
	const activeModalProduct = find(tiles, {_id: activeModalProductId});
	const metaTitle = seo.metaTitle || `${title} - Genexa`;
	const openGraphTitle = seo.openGraphTitle || `${title} - Genexa`;
	const openGraphImage = seo.openGraphImage;
	const twitterTitle = seo.twitterTitle || `${title} - Genexa`;
	const twitterImage = seo.twitterImage;

	// Filter Products
	const filteredTiles = filter(tiles, tile => {
		let ages = [];
		let symptoms = [];
		// Reduce ages/symptoms filters out of nested bundle products
		if (tile._type === 'product') {
			ages = tile.ages || ages;
			symptoms = tile.symptoms || symptoms;
		} else if (tile._type === 'bundle') {
			ages = reduce(
				tile.products,
				(ages, product) => {
					if (product.ages) {
						return [...ages, ...product.ages];
					} else {
						return ages;
					}
				},
				[],
			);

			symptoms = reduce(
				tile.products,
				(symptoms, product) => {
					if (product.symptoms) {
						return [...symptoms, ...product.symptoms];
					} else {
						return symptoms;
					}
				},
				[],
			);
		}

		let isAgeMatch = false;
		let isSymptomMatch = false;

		if (activeAgeFilters.length) {
			for (let i = 0; i < activeAgeFilters.length; i++) {
				if (ages.indexOf(activeAgeFilters[i]) > -1) {
					isAgeMatch = true;
				}
			}
		} else {
			isAgeMatch = true;
		}

		if (activeSymptomFilters.length) {
			for (let i = 0; i < activeSymptomFilters.length; i++) {
				if (symptoms.indexOf(activeSymptomFilters[i]) > -1) {
					isSymptomMatch = true;
				}
			}
		} else {
			isSymptomMatch = true;
		}

		return isAgeMatch && isSymptomMatch;
	});

	let sortedTiles = [...filteredTiles];

	if (sortBy === 'lowHigh') {
		sortedTiles = _sortBy(sortedTiles, 'cents');
	} else if (sortBy === 'highLow') {
		sortedTiles = _sortBy(sortedTiles, 'cents').reverse();
	}

	// Track collection view only once
	useEffect(() => {
		if (app.analytics) {
			const products = filter(sortedTiles, {_type: 'product'});

			app.analytics.viewCollection(
				products.map(product => ({
					id: product.sku,
					name: product.title,
					brand: 'Genexa',
					category: 'Medication',
					variant: product.variantTitle,
					quantity: 1,
					price: product.cents * 100,
				})),
			);
		}
	}, [0]);

	const onToggleSymptom = _id => {
		if (activeSymptomFilters.indexOf(_id) > -1) {
			setSymptomFilters(without(activeSymptomFilters, _id));
		} else {
			setSymptomFilters(uniq([...activeSymptomFilters, _id]));
		}
	};

	const onToggleAge = _id => {
		if (activeAgeFilters.indexOf(_id) > -1) {
			setAgeFilters(without(activeAgeFilters, _id));
		} else {
			setAgeFilters(uniq([...activeAgeFilters, _id]));
		}
	};

	const desktopCollectionNav = (
		<Drawer
			openByDefault={true}
			renderTrigger={({onClick, isOpen}) => (
				<button title={'Shop '+title} aria-label={'Shop '+title} className="fw--800 df jcb x aic tl" onClick={onClick}>
					<span>
						{'Shop '}
						<span className="link--underline-invert">{title}</span>
					</span>
					<img
						className={classNames('drawer__arrow-flip ml1', {
							active: isOpen,
						})}
						src="/images/icon-arrow-drawer.svg"
						alt="arrow"
					/>
				</button>
			)}>
			<div className="pt1">
				{collectionNav.map((collection, index) => (
					<div key={index}>
						<span className="o0 pen spacer">{'Shop '}</span>
						<Link
							to={`/collections/${collection.slug}`}
							className={classNames('link--underline-small', {
								active: collection.slug === slug,
							})} aria-label={collection.title}>
							{collection.title}
						</Link>
					</div>
				))}
			</div>
		</Drawer>
	);

	const mobileCollectionNav = (
		<SelectDrawer
			dropdownAlignment="top left right"
			renderTrigger={({onClick, isOpen}) => (
				<button  title={'Shop '+title + ' '+ sortedTiles.length + ' items'} aria-label={'Shop '+title  + ' '+ sortedTiles.length + ' items'} className="fw--800 x tl df fdr jcb" onClick={onClick}>
					<div>
						{'Shop '}
						<span className="link--underline-invert">{title}</span>
						<img
							className={classNames('drawer__arrow-flip ml1', {
								active: isOpen,
							})}
							src="/images/icon-arrow-drawer.svg"
							alt="arrow"
						/>
					</div>
					<div>
						<p className="fw--400">{sortedTiles.length} items</p>
					</div>
				</button>
			)}>
			{({onClose, isOpen}) =>
				collectionNav.map((collection, index) => (
					<div key={index}>
						<Link
							onClick={onClose}
							to={`/collections/${collection.slug}`}
							className={classNames('link--underline-small', {
								active: collection.slug === slug,
							})}>
							{collection.title}
						</Link>
					</div>
				))
			}
		</SelectDrawer>
	);

	const symptomDrawer = (
		<Drawer
			renderTrigger={({onClick, isOpen}) => (
				<button
					title="Symptom"
					aria-label="Symptom"
					className="fw--800 df fdr jcc aic tl x jcb link--trigger"
					onClick={onClick}>
					<span className="link--underline">Symptom</span>
					<img
						className={classNames('drawer__arrow-flip ml1', {
							active: isOpen,
						})}
						src="/images/icon-arrow-drawer.svg"
						alt="arrow"
					/>
				</button>
			)}>
			<div className="pt1 drawer__content-scrollable">
				<div className="pb6 ofy--scroll" style={{maxHeight: 200}}>
					{allSymptoms.map((symptom, index) => (
						<div key={index}>
							<button
								title={symptom.title}
								aria-label={symptom.title}
								onClick={() => onToggleSymptom(symptom._id)}
								className="df fdr jcb aic x tl link--trigger">
								<span className="db link--underline-small">
									{symptom.title}
								</span>
								{activeSymptomFilters.indexOf(symptom._id) >
									-1 && (
									<img
										className="db link--opacity"
										src="/images/icon-close-filter.svg"
										alt="Close"
									/>
								)}
							</button>
						</div>
					))}
				</div>
			</div>
		</Drawer>
	);

	const mobileSymptomDrawer = (
		<SelectDrawer
			dropdownAlignment="top left right"
			renderTrigger={({onClick, isOpen}) => (
				<button title="Filter by Symptom"	aria-label="Filter by Symptom" className="x tl df fdr jcb" onClick={onClick}>
					<div>
						{'Filter by '}
						<span className="link--underline-invert-small">
							Symptom
						</span>
						<img
							className={classNames('drawer__arrow-flip ml1', {
								active: isOpen,
							})}
							src="/images/icon-arrow-drawer.svg"
							alt="arrow"
						/>
					</div>
				</button>
			)}>
			{({onClose, isOpen}) =>
				allSymptoms.map((symptom, index) => (
					<div key={index}>
						<button
							title={symptom.title}
							aria-label={symptom.title}
							onClick={() => onToggleSymptom(symptom._id)}
							className="df fdr jcb aic x tl link--trigger">
							<span className="db link--underline-small">
								{symptom.title}
							</span>
							{activeSymptomFilters.indexOf(symptom._id) > -1 && (
								<img
									className="db link--opacity"
									src="/images/icon-close-filter.svg"
									alt="Close"
								/>
							)}
						</button>
					</div>
				))
			}
		</SelectDrawer>
	);

	const ageDrawer = (
		<Drawer
			renderTrigger={({onClick, isOpen}) => (
				<button
					title="Age"
					aria-label="Age"
					className="fw--800 df fdr jcc aic tl x jcb link--trigger"
					onClick={onClick}>
					<span className="link--underline">Age</span>
					<img
						className={classNames('drawer__arrow-flip ml1', {
							active: isOpen,
						})}
						src="/images/icon-arrow-drawer.svg"
						alt="arrow"
					/>
				</button>
			)}>
			<div className="pt1">
				{allAges.map((age, index) => (
					<div key={index}>
						<button
							title={age.title}
							aria-label={age.title}
							onClick={() => onToggleAge(age._id)}
							className="df fdr jcb aic x tl link--trigger">
							<span className="db link--underline-small">
								{age.title}
							</span>
							{activeAgeFilters.indexOf(age._id) > -1 && (
								<img
									className="db link--opacity"
									src="/images/icon-close-filter.svg"
									alt="Close"
								/>
							)}
						</button>
					</div>
				))}
			</div>
		</Drawer>
	);

	const mobileAgeDrawer = (
		<SelectDrawer
			dropdownAlignment="top left right"
			renderTrigger={({onClick, isOpen}) => (
				<button title="Filter by Age"	aria-label="Filter by Age" className="x tl df fdr jcb" onClick={onClick}>
					<div>
						{'Filter by '}
						<span className="link--underline-invert-small">
							Age
						</span>
						<img
							className={classNames('drawer__arrow-flip ml1', {
								active: isOpen,
							})}
							src="/images/icon-arrow-drawer.svg"
							alt="arrow"
						/>
					</div>
				</button>
			)}>
			{({onClose, isOpen}) =>
				allAges.map((age, index) => (
					<div key={index}>
						<button
							title={age.title}
							aria-label={age.title}
							onClick={() => onToggleAge(age._id)}
							className="df fdr jcb aic x tl link--trigger">
							<span className="db link--underline-small">
								{age.title}
							</span>
							{activeAgeFilters.indexOf(age._id) > -1 && (
								<img
									className="db link--opacity"
									src="/images/icon-close-filter.svg"
									alt="Close"
								/>
							)}
						</button>
					</div>
				))
			}
		</SelectDrawer>
	);

	const productSortDesktop = (
		<Select
			label="Sort by"
			value={sortBy}
			dropdownAlignment="top right"
			options={SORT_OPTIONS}
			optionClassName="ws--nowrap"
			triggerClassName="fw--400"
			labelClassName="link--underline-invert-small"
			onChange={setSortBy}
		/>
	);

	const productSortMobile = (
		<Select
			label="Sort by"
			value={sortBy}
			dropdownAlignment="top left right"
			options={SORT_OPTIONS}
			optionClassName="ws--nowrap"
			triggerClassName="fw--400"
			labelClassName="link--underline-invert-small"
			onChange={setSortBy}
		/>
	);

	const sidebarCol = (
		<div className="col show--md c4 c2--lg collection__sidebar psy reveal__slide reveal__delay--2 pt2">
			<div className="mb2">{desktopCollectionNav}</div>
			<div className="mb2">{symptomDrawer}</div>
			<div className="mb2">{ageDrawer}</div>
		</div>
	);
	
	const gridCol = (
		<div className="col c8--md c10--lg reveal__slide reveal__delay--3">
			{collection.topBannerImage &&<div><img src={collection.topBannerImage.url} alt={collection.topBannerImage.alt}/></div>}
			<div className="df fdr jce show--md mb3">
				<p className="mr2">{sortedTiles.length} items</p>
				{productSortDesktop}
			</div>

			<div className="hide--md mb3">
				<div className="mb1">{mobileCollectionNav}</div>
				<div className="mb1">{mobileAgeDrawer}</div>
				<div className="mb1">{mobileSymptomDrawer}</div>
				<div className="mb1">{productSortMobile}</div>
			</div>

			<div className="of--hidden">
				<CollectionProductGrid
					tiles={sortedTiles}
					collectionSlug={slug}
					bulkDiscountMinimumItems={bulkDiscountMinimumItems}
					bulkDiscountPercentage={bulkDiscountPercentage}
					onQuickView={id => {
						setModalProductId(id);
						setShowProductModal(true);
					}}
				/>
			</div>
		</div>
	);

	const collectionModal = activeModalProduct && (
		<ProductQuickViewModal
			isOpen={showProductModal}
			onClose={() => setShowProductModal(false)}
			{...activeModalProduct}
		/>
	);

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
			/>
			<div className="grid-container contained mt5--md mb6 mb12--md">
				<Reveal>
					<div className="row">
						{sidebarCol}
						{gridCol}
					</div>
				</Reveal>
			</div>
			<ComponentList components={components} />
			<HorizontalRule width={'c10'} />
			{collectionModal}
		</React.Fragment>
	);
};

import React, {useState, useEffect} from 'react';
import shopify from '../api/shopify';
import {encode} from '../utils/shopify-gid';
import {connectToStore} from './Provider';

const InventoryProductProvider = ({
	productId,
	isAddingItem,
	addItemAndOpenCart,
	renderLoading,
	children,
}) => {
	const [product, setProduct] = useState(null);

	useEffect(() => {
		shopify.product.fetch(encode('Product', productId, {
			accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN
		})).then(response => {
			setProduct(response);
		});
	}, [productId]);


	if (!product) {
		return renderLoading({
			product: null,
			isAddingItem,
			addItemAndOpenCart,
		});
	} else {
		return children({
			product,
			isAddingItem,
			addItemAndOpenCart,
		});
	}
};


InventoryProductProvider.defaultProps = {
	renderLoading: () => null,
	children: () => null,
};

const mapStateToProps = ({
	isAddingItem,
}) => ({
	isAddingItem,
});

const mapStoreToProps = ({
	addItemAndOpenCart,
}) => ({
	addItemAndOpenCart,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(InventoryProductProvider);